// components/FeatureCard.js
import React from 'react';
import { Card, Typography, Grid, List, ListItem } from '@mui/material';

const FeatureCard = ({ header, features, image, height = '200px', imageFit = 'cover' }) => (
  <Card sx={{
    display: 'flex',
    flexDirection: 'row',
    background: 'rgba(255, 255, 255, 0.1) !important', // semi-transparent white
    backdropFilter: 'blur(15px)', // blur effect
    border: '1px solid rgba(255, 255, 255, 0.18)', // optional: add a border
    height: '100%'
  }}>
    <Grid container item xs={8} direction="column" style={{padding: '.5em'}}>
      <Grid item>
        <Typography variant="h6" color="white" style={{fontFamily: 'Poppins', fontWeight: '700'}}>{header}</Typography>
      </Grid>
      <Grid item sx={{ flex: 1 }}>
        <List component="ul" sx={{ paddingLeft: '1em', overflowY: 'scroll', 
          height: height,
          '& li': { 
            color: 'white', 
            paddingLeft: '16px', 
            position: 'relative',
            fontFamily: 'Poppins',
            '&::before': {
                content: '"•"',
                position: 'absolute',
                left: '0',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '1.2em',
                lineHeight: '1em'
            }
        } }}>
          {features.map((feature, index) => (
            <ListItem key={index} component="li">{feature}</ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
    <Grid item xs={4} sx={{ display: 'flex' }}>
      <img src={image} alt={header} style={{ flex: 1, width: '100%', height: '100%', objectFit: imageFit }} />
    </Grid>
  </Card>
);

export default FeatureCard;
