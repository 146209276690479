import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SolutionCard from '../components/SolutionCard';
import { getSourceSet } from '../utils';
import { graphql } from 'gatsby'
import { Typography, Fab } from "@material-ui/core";
import { StaticImage } from "gatsby-plugin-image"
import { TextField, Button, backdropClasses } from "@mui/material";
import minutefeature_graphic from '../images/minutefeature.png';
import hourfeature_graphic from '../images/hourfeature.png';
import {Grid, Container} from '@material-ui/core/';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BusinessIcon from '@material-ui/icons/Business';
import automateIcon from '../images/automate.png';
import filingIcon from '../images/digitalfiling.png'
import orderIcon from '../images/order.png';
import uxIcon from '../images/uxsimulation.png';
import notificationsIcon from '../images/notifications.png';
import securityIcon from '../images/security.png';
import catIcon from '../images/automation.png';
import subdomainIcon from '../images/subdomain.png';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Link } from "gatsby";
import Etimology from '../Etimology.json';
import Catalina from '../Catalina.json';
import invoice from '../invoice.json';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import EmailTwoToneIcon from '@material-ui/icons/EmailTwoTone';
import FAQSection from "../FAQSection";
import branding from '../images/branding.mp4';
import clientPortal from '../images/clientportal.mp4'
import macbook from '../images/brandingmac.png';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import business1 from '../images/business1.jpeg';
import stressedbusiness from '../images/stressedbusiness.jpeg';
import cattools from '../images/cattools.png';
import security from '../images/security.jpeg';
import solutionsBanner from '../images/solution.mp4';
import solutionsposter from '../images/solution.webp';
import './solutions.css';
import FeatureCard from "../components/FeatureCard";
import clientOnboarding from '../images/clientportal.webp';
import paymentProcessing from '../images/invoicing.webp'
import trados from '../images/trados.webp';
import customization from '../images/branding.webp';
import cloud from '../images/cloud.webp';
import projectManagement from '../images/projectmanagement.webp';
import websitebuilder from '../images/websitebuilder.webp';
import lspinhouse from '../images/lspinhouse.webp'


const SolutionsPage = () => {

    const minuteFeatures = [
        'Securely submit projects (ISO and GDPR compliant)',
        'Request quotes',
        'Track project status',
        'Download completed projects',
        'Communicate with PMs',
        'Track order history and changes',
        'Receive in-app and email notifications',
        'Review financials'
    ];

    const hourFeatures = [
        "Launch its own subdomain and login page",
        "Upload your own logo",
        "Use an industry-leading Simulation Experience to easily customize the portal's user interface",
        "Integrate with your favorite TMS, CAT tool or platform",
        "Onboard clients",
        "Automatically propagate trans and loc projects into your pipeline",
        "Choose from over a dozen fresh, modern themes"
    ]

    const subContent = {
        fontFamily: 'Poppins',
        fontSize: '1.5em',
        fontWeight: 'medium',
        lineHeight: '35px',
      
      }

      const blurOverlayContet = {
        backgroundClip: 'padding-box',
        // '-webkit-mask-image': 'linear-gradient(to right bottom, transparent 38%, black 60%)',
        '-webkit-mask-image': 'linear-gradient(to right bottom, transparent 38%, black 48%)'
      }

      const reverseBlurOverlay = {
        backgroundClip: 'padding-box',
        // '-webkit-mask-image': 'linear-gradient(to right top, transparent 38%, black 60%)',
        '-webkit-mask-image': 'linear-gradient(to right top, transparent 38%, black 68%)'
      }

      const maskStyles  = {
        WebkitMask: 'url(#diagonal-mask)',
        mask: 'url(#diagonal-mask)'
      }

    const theme = useTheme();
    const xlScreen = useMediaQuery(theme.breakpoints.down('xl'));
    const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [open, setOpen] = React.useState(false);

    const blurOverlayStyle = {
        // position: 'absolute',
        // top: '0',
        // left: '0',
        // right: '0',
        // bottom: '0',
        // background: '#ccc',
        // filter: 'blur(5px)',
        backgroundClip: 'padding-box',
        '-webkit-mask-image': xsScreen ? 'linear-gradient(to left bottom, transparent 50%, black 85%)' : 'linear-gradient(to bottom left, transparent 33%, black 66%)',
        // transition: 'mask-image 2s;',
        // 'webkit-mask-position': '100% 0',
        // animation: 'slideMask 5s infinite',
      };

    <svg width="0" height="0" position="absolute">
    <defs>
        <mask id="diagonal-mask" maskUnits="objectBoundingBox" maskContentUnits="objectBoundingBox">
        <polygon points="0,1 0.33,1 0.67,0 1,0 1,1 0,1" fill="white"/>
        </mask>
    </defs>
    </svg>

    return (<Layout currentPage="solutions" overridePadding={smScreen ? true: false}>
        <SEO title={'Solutions'} 
          description="We have the solutions for your business"/>
          <Grid container column spacing={8} style={{paddingTop: '5em', paddingBottom: '40px', fontSize: xsScreen ? '10px' : smScreen ? '12px' : mdScreen ? '14px' : '16px'}}>
          <div style={{position: 'absolute', height: '30em', width: '100%', overflow: 'hidden', top: '0', left: '0', zIndex: 0}}>
                <div  style={{position: 'absolute', height: '100%', width: '100%', display: 'grid'}}>
                <Grid container xs={12} style={{alignItems: 'center', flexFlow: 'column', position: 'absolute', marginTop: '10em'}}>
                <Typography variant="h1" style={{fontSize: '4em'}}>What's your story?</Typography>
                <Typography variant="h6" style={{fontStyle: 'italic', fontFamily: 'fangsong', fontSize: '1.5em'}}>Let us be a part of it</Typography>
                </Grid>
                    <video id="solutions-banner" loop autoPlay muted playsInline poster={solutionsposter} style={{height: '100%', width: '100%', objectFit: 'cover', ...blurOverlayStyle}}>
                        <source src={solutionsBanner} type="video/mp4" />
                        {/* <source src="http://www.sample-videos.com/video/mp4/720/big_buck_bunny_720p_1mb.mp4" type="video/ogg" /> */}
                        Your browser does not support the video tag.
                    </video>
                </div>
                </div>
            <Grid container item row justifyContent="center" style={{position: 'relative', marginTop: '25em', padding: xsScreen ? '4em' : '5em'}}>
                <Grid  style={{position: 'absolute', zIndex: 0, height: '90%', width: '200%', ...blurOverlayContet,  backgroundColor: 'rgb(174 174 195 / 25%)'}}></Grid>
                <Grid item container xs={12} style={{alignItems: 'center', zIndex: 2}}>
                    <Grid item xs={4} style={{order: mdScreen ? 2 : 3, justifyContent: 'center'}} container><Avatar sx={{ height: xsScreen ? '5em' : smScreen ? '7.5em' : mdScreen ? '10em' : '15em', width: xsScreen ? '5em' : smScreen ? '7.5em' : mdScreen ? '10em' : '15em', marginTop: mdScreen ? '0' : '-8em'}} src={business1}></Avatar></Grid>
                    <Grid item xs={8} style={{order: 1}}>
                    <Typography  variant="h4" style={{ fontWeight: 500, fontSize: xsScreen ? '1.75em' : '3em', fontFamily: 'Poppins'}}>Looking to upscale your language business, operating with the polish of
leading LSPs?</Typography>
                    </Grid>
                    <Grid item md={12} lg={8} style={{order: mdScreen ? 3 : 2}}>
                        <Typography variant="body1" style={{lineHeight: 1.75,  fontSize: '1.25em', marginTop: '2em', fontFamily: 'Poppins'}}> Metalinguist swiftly establishes a modern client portal, complete with your own
website domain, in just 5 minutes. Our user-friendly, customizable and cost-effective
cloud solution streamlines business and project management, allowing clients to easily
navigate the platform with no learning curve. Say goodbye to manual reminders and
lengthy email threads. With Metalinguist, your focus remains where it should be – on
language, your clients, and growing your business. </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item row justifyContent="center" style={{position: 'relative',  padding: xsScreen ? '4em' : '5em'}}>
                <Grid  style={{position: 'absolute', zIndex: 0, height: '90%', width: '200%', ...reverseBlurOverlay, backgroundColor: 'rgb(174 174 195 / 25%)'}}></Grid>
                <Grid item container xs={12} style={{alignItems: 'center', justifyContent: xlScreen ? 'flex-end' : 'auto', zIndex: 2, marginTop: '2.5em'}}>
                    <Grid item xs={4} style={{order: 1, justifyContent: 'center'}} container><Avatar sx={{ height: xsScreen ? '5em' : smScreen ? '7.5em' : mdScreen ? '10em' : '15em', width: xsScreen ? '5em' : smScreen ? '7.5em' : mdScreen ? '10em' : '15em', marginTop:  mdScreen ? '0' : '8em'}} src={stressedbusiness}></Avatar></Grid>
                    <Grid item xs={8} style={{order: 2, marginTop: mdScreen ? '0' : '-10em'}}>
                        <Typography  variant="h4" style={{ fontWeight: 500, fontSize: xsScreen ? '1.75em' : '3em', fontFamily: 'Poppins'}}>Are you an LSP or corporate language division with an in-house portal, evergrowing in cost and scope?  </Typography>
                    </Grid>
                    <Grid item md={12} lg={8} style={{order:  3, marginTop: mdScreen ? '0' : '-13.5em'}}>
                        <Typography variant="body1" style={{lineHeight: 1.75,  fontSize: '1.25em', marginTop: '2em', fontFamily: 'Poppins'}}> Metalinguist was incepted from this very use case, and it is one of the most common for
SaaS (Software as a Service). Engineered by tech leaders at the pinnacle of the
industry, our platform is designed for optimal efficiency—savings we pass on to you.
Whether you choose to run on our cloud or host an instance on your own platform,
you'll find Metalinguist significantly reducing your technical operating costs whilst
providing the paradigm experience for both your team and clients. </Typography>
                       
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item row justifyContent="center" style={{position: 'relative', marginTop: '2em',  padding: xsScreen ? '4em' : '5em'}}>
                <Typography variant="h4" style={{fontWeight: 500, fontSize: '3.5em', fontFamily: 'Poppins'}}>Feature Overview</Typography>
                <Grid container spacing={3} style={{marginTop: '2em'}}>
                    <Grid item xs={12} md={6} lg={4}>
                        <FeatureCard 
                        header="Instant Client Portal" 
                        features={['5 minutes to setup with your own website address', 
                        'Invite clients directly from the app with automated emails and secure password setup', 
                        'Intake your first project within 10 minutes',
                        'Be an expert in the system from the first click',
                        'Intuitive UI and AI assisted in-app tutorials eliminate customer onboarding overhead']} 
                        image={clientOnboarding}
                        height={'15em'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FeatureCard 
                        header="Seamless Trados Integration" 
                        features={['Two click Trados integration', 
                        'Seamlessly sync your customers between systems with CATalina™', 
                        'Import and export projects',
                        'Automated file flow between Metalinguist and Trados']} 
                        image={trados}
                        height={'15em'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FeatureCard 
                        header="In-App Payment Processing" 
                        features={[ 'Invoice management',
                        'Secure invoice and project payment (for select customers)',
                        'Integrate with accounting and invoicing tools or simply export to excel', 
                        'Scale business with direct payment processing',
                        'Automatic payment reminders']} 
                        image={paymentProcessing}
                        height={'15em'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FeatureCard 
                        header="White Labeling & Branding" 
                        features={['Personalize your portal to match your brand', 
                        'Customize settings, workflows, email templates,  branding and more for each of your clients',
                         'No coding required, WYSIWYG paradigm allows for confident changes with live component preview prior to publishing',
                        '28 total themes and counting']} 
                        image={customization}
                        height={'15em'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FeatureCard 
                        header="Project Management" 
                        features={['Quotes & Orders', 
                        'Default workflows with ability to customize',
                        'Automated emails and in-app notifications',
                        'Easy account management and entitlement provisioning',
                        'Performance reporting',
                        'Time tracking',
                        'Project manager roles (for LSP+subscriptions)']} 
                        image={projectManagement}
                        height={'15em'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FeatureCard 
                        header="LSP and In-House Teams" 
                        features={['Granular access with roles and entitlements', 
                        'Assign users to one or many companies', 
                        'Delegate management tasks with superclient and project manager roles',
                        'Integrate with CAT tools, Quickbooks and more',]} 
                        image={lspinhouse}
                        height={'15em'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FeatureCard 
                        header="Secure Cloud Workflow" 
                        features={["End-to-end encryption, protecting your clients' sensitive information", 
                        'Environments provisioned using cloud security best practices', 
                        'Store and archive records securely for later review or revision',
                        'Centralized digital filing system for all your files']} 
                        image={cloud}
                        height={'15em'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FeatureCard 
                        header="Website Builder" 
                        features={["Go live with your professional website in just 10-15 minutes", 
                        'CMS functionality with zero coding requirements and intuitive simulation interface', 
                        'Boost your online visibility and search rankings right from the start with SEO',
                        'Reach new customers with advertising integrations (select partners)']} 
                        image={websitebuilder}
                        height={'15em'}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item row justifyContent="center" style={{position: 'relative', padding: xsScreen ? '4em' :'5em'}}>
                <Grid item xs={12} style={{textAlign: 'center', zIndex: 2, padding: '2.5em', paddingTop: '4em', paddingBottom: '4em', backgroundColor: 'rgb(87 88 94 / 21%)', WebkitBackdropFilter: 'blur(15px)', backdropFilter: 'blur(15px)'}}>
                    <Typography variant="h4" style={{fontWeight: 500, fontSize: '2.5em', fontFamily: 'Poppins'}}>Ready to transform your language service operations?</Typography>
                    <Typography variant="body1" style={{lineHeight: 1.75,  paddingTop: '2.5em', paddingLeft:  xsScreen ? '0' : '4em', paddingRight:  xsScreen ? '0' : '4em', fontFamily: 'Poppins', fontSize: '1.25em'}}>
                    Whether you&#39;re an established LSP, an in-house language team, or a freelancer aspiring
to operate like a leading LSP, Metalinguist is your solution. Streamline your client
operations with our project management portal, reduce operational costs, and focus on
what really matters—delivering exceptional language services. From seamless project
management to secure cloud workflows, Metalinguist has you covered.
                    <br></br><br></br>
                    Take the next
step in scaling your business and enhancing your clients&#39; experience—choose to be a
Metalinguist today. 
                    </Typography>
                    <Grid container spacing={2} style={{alignItems: 'center', justifyContent: 'center', paddingTop: '2.5em'}}>
                    {/* <Grid item lg="6"><TextField autofocus label="Email Address" variant="standard" color="primary" focused fullWidth></TextField></Grid> */}
                    <Fab variant="extended" color="primary" style={{background: 'linear-gradient(180deg, #ffffff, #c4c4c4', fontSize: smScreen ? '10px' : 'auto', height: smScreen ? '25px' : '40px', marginRight: '1em'}}><Link to="/contact"><b style={{fontFamily: 'Source Sans Pro', fontSize: '1em', color: '#36566E'}}>Contact Sales</b></Link></Fab>
                    <Fab variant="extended" color="primary" onClick={() => window.location.href = 'https://app.metalinguist.com/onboarding'} style={{background: 'linear-gradient(180deg, #53768e, #254D6A)', fontSize: smScreen ? '10px' : 'auto', height: smScreen ? '25px' : '40px'}}><b style={{fontFamily: 'Source Sans Pro', fontSize: '1em'}}>Sign Up</b></Fab>
                    </Grid>
                    {/* <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', fontFamily: 'Source Sans Pro', fontSize: '1.25em'}}>
                    Our intuitive user interface allows your clients to painlessly acclimate, onboard and create translation projects within minutes. Say goodbye to email, and hello to a portal that your clients will actually enjoy.
                    </Typography> */}
                </Grid>
            </Grid>
            {/* <Grid container item row justifyContent="center" style={{position: 'relative'}}>
                <Grid item container xs={12} style={{alignItems: 'center'}}>
                    <Grid item xs={4} style={{order: 2, justifyContent: 'center'}} container><Avatar sx={{ height: '15em', width: '15em'}} src={business1}></Avatar></Grid>
                    <Grid item xs={8} style={{order: 1}}>
                        <Typography variant="h3">You serve a broad set of clients and employ subcontractors.</Typography><br></br>
                        <Typography variant="h5"> As the business grows you struggle to compartmentalize information and onboard resources in a timely fashion and find an easy way to integrate subs into your workflow without exposing business or client information</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item row justifyContent="center" style={{position: 'relative'}}>
                <Grid item container xs={12} style={{alignItems: 'center'}}>
                    <Grid item xs={4} style={{order: 1, justifyContent: 'center'}} container><Avatar sx={{ height: '15em', width: '15em'}} src={stressedbusiness}></Avatar></Grid>
                    <Grid item xs={8} style={{order: 2}}>
                        <Typography variant="h3">Do you heavily work in Trados ? </Typography><br></br>
                        <Typography variant="h5">You haven’t found a client portal that seamlessly integrates with their project management and cat tool offerings? 
                        </Typography>
                    </Grid>
                </Grid>
            </Grid> */}
            
          </Grid>
          <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container justify="center" style={{height: '100%', alignItems: 'center'}}>
            <Box style={{display: 'flex', justifyContent: 'center', width: '600px', backgroundColor: 'transparent', height: 'fit-content', overflowY: 'scroll', position: 'relative'}}>
                <IconButton onClick={() => setOpen(false)} style={{position: 'absolute', top: '.5em', right: '3em', color: 'white'}}><CloseIcon /></IconButton>
                <iframe src='https://formcrafts.com/a/qxndssw?iframe=true&resizer=false&innerHeight=1173' width='612' height='1173' style={{maxWidth: '85%', maxHeight: '700px', border: '0'}}></iframe>
            </Box>
          </Grid>
      </Modal>
        </Layout>
    )
}

export default SolutionsPage;


{/* <Grid item sm={6} style={{textAlign: 'left', padding: '2.5em', zIndex: 1}}>
                <Typography variant="h4" style={{fontWeight: 700, fontSize: '2.5em'}}>
                Metalinguist Client Portal 
                </Typography>
                <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', fontFamily: 'Source Sans Pro', fontSize: '1.25em', ...subContent}}>
                Through good user interface design, based on thousands of hours of user research, the Metalinguist client portal takes 15 minutes to set up and onboard your first client.
                5 minute onboarding 
                -intuitive ui and in app guides result in no training required
                -WSYWIG (what you see is what you get) interface allows to preview your settings and be confident that clients are getting a custom yet streamlined onboarding experience
                -Easy account managemtn allows for adding of clients,
                -customizable automated emails and in app notifications 
                -integrated file and document uploads with basic project management
                </Typography>
            </Grid>
            <Grid item sm={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1}}>
                <StaticImage src="../images/home_portal.png"
                quality={95}
                placeholder={'TRACED_SVG'}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Metalinguist"
                fadeIn={true} 
                loading="eager"
                style={{ height: '27em', width: '36em',position: 'absolute', zIndex: 1}}
                objectFit="contain"
                />
            </Grid>
            </Grid>
            <Grid container item row justifyContent="center" style={{position: 'relative'}}>
            <Grid  style={{position: 'absolute', zIndex: 0, backdropFilter: 'blur(7px)', height: '100%', width: '200%', backgroundColor: 'rgba(240, 248, 255, 0.25)'}}></Grid>
                <Grid item sm={6} style={{textAlign: 'left', padding: '2.5em', zIndex: 1}}>
                    <Typography variant="h4" style={{fontWeight: 700, fontSize: '2.5em'}}>
                    Invoicing and Payment Processing
                    </Typography>
                    <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', fontFamily: 'Source Sans Pro', fontSize: '1.25em', ...subContent}}>
                    Say goodbye to third-party payment portals; manage your invoices and have your clients process payments, all under one system. 
                    Plus, keep an eye out for the Quickbooks integration, scheduled for an early 2024 release. 
                    </Typography>
                </Grid>
                <Grid item sm={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1}}>
                    <StaticImage src="../images/home_portal.png"
                    quality={95}
                    placeholder={'TRACED_SVG'}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Metalinguist"
                    fadeIn={true} 
                    loading="eager"
                    style={{ height: '27em', width: '36em',position: 'absolute', zIndex: 1}}
                    objectFit="contain"
                    />
                </Grid>
            </Grid> */}